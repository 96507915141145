import { templateTypes, clientTypes, categoryTypes } from '@app/types';
import { CrudService, TAction, TActionT, TCrudState } from '@dariosoft/tools';
import { StoreManager } from '../store-manager';
import * as api from '@app/api';
import { MessageBox, TSelectItem, TTreeNode } from '@dariosoft/components';
import { PayloadAction } from '@reduxjs/toolkit';

type TParams = {
    clientId: string,
    categoryId: string,
};

type TListParams = {
    clientId: string,
    categoryId: string
}

type TState = TCrudState<templateTypes.TTemplateInfo, templateTypes.TTemplateModel, TParams, TListParams> & {
    initialized: boolean,
    autoGeneratePath: boolean,
    clientInfo: clientTypes.TClientModel | undefined | null
}

type TReducers = {
    setAutoGeneratePath: TActionT<TState, boolean>,
    setCategoryId: TActionT<TState, { id: string }>,
}

export class TemplateService extends CrudService<templateTypes.TTemplateInfo, templateTypes.TTemplateModel, TParams, TState, TReducers> {
    constructor(clientId: string) {
        super(StoreManager, {
            name: `services/template-service/${clientId}`,
            getInitalState: () => {
                return {
                    loading: false,
                    clientInfo: null,
                    initialized: false,
                    autoGeneratePath: true,
                    params: {
                        clientId: clientId,
                        categoryId: clientId
                    },
                    model: templateTypes.createDefaultTemplateInfo(),
                    context: { templateId: '', dataModel: '{}', subject: '', body: '<div></div>' },
                    list: {
                        items: [],
                        totalCount: 0,
                        editingId: undefined,
                        selectedItem: undefined,
                        tempQuery: ''
                    },
                    listLoadModel: {
                        pageIndex: 0,
                        pageSize: 15,
                        params: {
                            clientId: clientId,
                            categoryId: clientId, // The rootCategory.Id equals to its owner client.Id
                        },
                        query: '',
                        sortBy: 'name',
                        sortOrder: 'asc'
                    }
                }
            },
            apis: {
                create: api.TemplateApi.instance.create.thunk,
                update: api.TemplateApi.instance.update.thunk,
                delete: api.TemplateApi.instance.delete.thunk,
                list: api.TemplateApi.instance.list.thunk,
            },
            additionalReducers: {
                setAutoGeneratePath: (state: TState, action: PayloadAction<boolean>): void => {
                    state.autoGeneratePath = action.payload;
                    if (state.autoGeneratePath)
                        state.model.path = this.generatePath({ lang: state.model.lang, name: state.model.name, version: state.model.version });
                },
                setCategoryId: (state: TState, action: PayloadAction<{ id: string }>): void => {
                    state.listLoadModel.params.categoryId = action.payload.id;
                    state.model.categoryId = action.payload.id;
                    state.params.categoryId = action.payload.id;
                    setTimeout(this.list.loadList, 10);
                }
            },
            actionReducerMapBuilder: (builder) => {
                builder.addCase(api.ClientApi.instance.get.thunk.pending, state => {
                    state.clientInfo = null;
                    state.loading = true;
                })
                    .addCase(api.ClientApi.instance.get.thunk.rejected, state => { state.loading = false })
                    .addCase(api.ClientApi.instance.get.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        try {
                            if (action.payload.isSuccessful && isPlainObject(action.payload.data)) {
                                state.clientInfo = action.payload.data;
                                setTimeout(this.list.loadList, 10);
                            }
                        }
                        finally {
                            state.initialized = true;
                            setTimeout(this.initialized.bind(this, { ...state }), 20);
                        }
                    })

                    .addCase(api.TemplateApi.instance.moveToCategory.thunk.pending, state => { state.loading = true })
                    .addCase(api.TemplateApi.instance.moveToCategory.thunk.rejected, state => { state.loading = false })
                    .addCase(api.TemplateApi.instance.moveToCategory.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            state.list.items.removeWhere(e => action.meta.arg.templateIds.contains(e.id));
                        }
                    })

                    .addCase(api.TemplateApi.instance.clone.thunk.pending, state => { state.loading = true })
                    .addCase(api.TemplateApi.instance.clone.thunk.rejected, state => { state.loading = false })
                    .addCase(api.TemplateApi.instance.clone.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful && action.payload.data) {
                           // let src = state.list.items.find(x => x.id == action.meta.arg)!;
                            state.list.items.unshift(action.payload.data);
                            MessageBox.toast.submitSuccessfull();
                        }
                    })
                    ;
            },
        }, true);

    }

    //#region PUBLICS
    public readonly languages: TSelectItem<string>[] = ['en-US', 'fa-IR', 'tr-TR', 'ar-SA', 'de-DE', 'fr-FR', 'es-ES', 'zh-cn'].map(x => ({ text: x, value: x }));
    public readonly getPreviewUrl = (templateId: string): string => api.TemplateApi.instance.getPreviewUrl(templateId);
    public readonly getClientInfo = (): clientTypes.TClientInfo | null | undefined => this.getState().clientInfo;
    public readonly getInitizlied = (): boolean => this.getState().initialized;

    public readonly getAutoGeneratePath = (): boolean => this.getState().autoGeneratePath;
    public readonly setAutoGeneratePath = (value: boolean): void => StoreManager.dispatch(this.slice.actions.setAutoGeneratePath(value));

    public readonly load = (categoryId: string): void => StoreManager.dispatch(this.slice.actions.setCategoryId({ id: categoryId }));  //api.ClientApi.instance.get.submit(categoryId);
    public readonly exsistsTemplate = (id: string): boolean => this.list.getItems().some(e => e.id == id);
    public readonly setCurrentCategory = (categoryId: string): void => {
        if (this.getState().listLoadModel.params.categoryId == categoryId) return;
        StoreManager.dispatch(this.slice.actions.setCategoryId({ id: categoryId }));
    }
    public readonly moveToCategory = (templateId: string, target: categoryTypes.TCategoryInfo): void => api.TemplateApi.instance.moveToCategory.submit({ targetCategoryId: target.id, templateIds: [templateId] });
    public readonly clone = (templateId: string): void => api.TemplateApi.instance.clone.submit(templateId);
    //#endregion

    //#region PROTECTEDS
    protected override onBeforeSubmit(item: templateTypes.TTemplateInfo, state: TState): void {
        item.ownerId = state.listLoadModel.params.clientId;
        item.categoryId = state.listLoadModel.params.categoryId;
    }

    protected override describeItem = (item: templateTypes.TTemplateModel): string => item.name;

    protected override onModelChanging = (state: TState, model: templateTypes.TTemplateInfo, payload: { key: keyof templateTypes.TTemplateInfo, value: any }): void => {
        if (payload.key == 'name' && state.autoGeneratePath)
            state.model.path = this.generatePath({ lang: state.model.lang, name: payload.value, version: model.version });

        if (payload.key == 'lang' && state.autoGeneratePath)
            state.model.path = this.generatePath({ lang: payload.value, name: state.model.name, version: model.version });

        if (payload.key == 'version') {
            payload.value = payload.value.replaceAll(' ', '');
            state.model.path = this.generatePath({ lang: state.model.lang, name: state.model.name, version: payload.value });
        }
    }
    //#endregion

    //#region PRIVATES
    private generatePath = (info: { lang: string, name: string, version: string }) => {
        return '/' + [info.lang.substring(0, 2), info.version ?? '', info.name.replaceAll(/[^0-9a-z]/gi, '-')]
            .filter(e => !String.isEmpty(e))
            .join('/').toLowerCase();
    }
    //#endregion
}