import { CrudService, TActionT, TCrudState } from '@dariosoft/tools';
import { clientTypes } from '@app/types';
import { StoreManager } from '../store-manager';
import { PayloadAction } from '@reduxjs/toolkit';
import { MessageBox } from '@dariosoft/components';
import * as api from '@app/api';

type TParams = {};

type TState = TCrudState<clientTypes.TClientInfo, clientTypes.TClientModel, TParams> & {
    credential: clientTypes.TClientCredentialInfo
}

type TReducers = {
    setCredential: TActionT<TState, { key: keyof clientTypes.TClientCredentialInfo, value: any }>,
}

export class ClientService extends CrudService<clientTypes.TClientInfo, clientTypes.TClientModel, TParams, TState, TReducers> {
    private constructor() {
        super(StoreManager, {
            name: 'services/client-service',
            getInitalState: () => {
                return {
                    loading: false,
                    params: {},
                    model: clientTypes.createDefaultClientInfo(),
                    credential: { clientId: '', adminUsername: '', adminPassword: '', apiKey: '', secretKey: '' },
                    list: {
                        items: [],
                        totalCount: 0,
                        editingId: undefined,
                        selectedItem: undefined,
                        tempQuery: ''
                    },
                    listLoadModel: {
                        pageIndex: 0,
                        pageSize: 15,
                        params: {},
                        query: '',
                        sortBy: 'name',
                        sortOrder: 'asc'
                    }
                }
            },
            apis: {
                create: api.ClientApi.instance.create.thunk,
                update: api.ClientApi.instance.update.thunk,
                delete: api.ClientApi.instance.delete.thunk,
                list: api.ClientApi.instance.list.thunk,
            },
            additionalReducers: {
                setCredential: (state: TState, action: PayloadAction<{ key: keyof clientTypes.TClientCredentialInfo, value: any }>): void => {
                    state.credential[action.payload.key] = action.payload.value;
                }
            },
            actionReducerMapBuilder: (builder) => {
                builder
                    .addCase(api.ClientApi.instance.getCredentials.thunk.pending, state => { state.loading = true })
                    .addCase(api.ClientApi.instance.getCredentials.thunk.rejected, state => { state.loading = false })
                    .addCase(api.ClientApi.instance.getCredentials.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (isPlainObject(action.payload.data)) {
                            state.credential.clientId = action.meta.arg;
                            state.credential.adminUsername = action.payload.data!.adminUsername;
                            state.credential.adminPassword = action.payload.data!.adminPassword;
                            state.credential.apiKey = action.payload.data!.apiKey;
                            state.credential.secretKey = action.payload.data!.secretKey;
                        }
                    })

                    .addCase(api.ClientApi.instance.setCredentials.thunk.pending, state => { state.loading = true })
                    .addCase(api.ClientApi.instance.setCredentials.thunk.rejected, state => { state.loading = false })
                    .addCase(api.ClientApi.instance.setCredentials.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            state.credential.adminPassword = '';
                            MessageBox.toast.submitSuccessfull();
                        }
                    })

                    .addCase(api.ClientApi.instance.generateSecretKey.thunk.pending, state => { state.loading = true })
                    .addCase(api.ClientApi.instance.generateSecretKey.thunk.rejected, state => { state.loading = false })
                    .addCase(api.ClientApi.instance.generateSecretKey.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            state.credential.secretKey = action.payload.data ?? '';
                            MessageBox.toast.submitSuccessfull();
                        }
                    })
                    ;
            }
        });

    }

    //#region PUBLICS
    public credentials = Object.freeze({
        getModel: (): clientTypes.TClientCredentialInfo => this.getState().credential,
        setModel: <TKey extends keyof clientTypes.TClientCredentialInfo>(key: TKey, val: clientTypes.TClientCredentialInfo[TKey]): void => { StoreManager.dispatch(this.slice.actions.setCredential({ key: key, value: val })) },
        updateAdminPassword: () => {
            MessageBox.modal.confirmProceedOperation().then(confirm => {
                if (!confirm) return;

                let model: any = { ...this.credentials.getModel() };
                delete model.secretKey;

                darioCrypto.lock(model.adminPassword).then(((model: any, lockedText: string) => {
                    model.adminPassword = lockedText;
                    api.ClientApi.instance.setCredentials.submit(model);
                }).bind(this, model));

            });

        },
        generateApiKey: () => {
            MessageBox.modal.confirmProceedOperation().then(confirm => {
                if (!confirm) return;
                let clientId = this.credentials.getModel().clientId;
                api.ClientApi.instance.generateSecretKey.submit(clientId);
            });
        },
        load: (item: clientTypes.TClientModel, done: Function): void => {
            api.ClientApi.instance.getCredentials.submit(item.id);
            done();
        }

    });
    public static get instance(): ClientService {
        if (ClientService._instance == null) ClientService._instance = new ClientService();
        return ClientService._instance!;
    }
    //#endregion

    //#region PROTECTEDS
    protected describeItem = (item: clientTypes.TClientModel): string => item.name;
    //#endregion

    //#region PRIVATES
    private static _instance: ClientService | null = null;
    //#endregion
}